import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weddingdetails',
  templateUrl: './weddingdetails.component.html',
  styleUrls: ['./weddingdetails.component.css']
})
export class WeddingdetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
