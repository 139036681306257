import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { ContactComponent } from './contact/contact.component';
import { WeddingdetailsComponent } from './weddingdetails/weddingdetails.component';
import { GiftsComponent } from './gifts/gifts.component';
import { HotelComponent } from './hotel/hotel.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AgmCoreModule } from '@agm/core'
import { environment } from 'src/environments/environment';

library.add(faBars);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    ContactComponent,
    WeddingdetailsComponent,
    GiftsComponent,
    HotelComponent,
    AboutusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    AgmCoreModule.forRoot({apiKey: environment.googleMapsApiKey})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
