import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { WeddingdetailsComponent } from './weddingdetails/weddingdetails.component';
import { HotelComponent } from './hotel/hotel.component';
import { GiftsComponent } from './gifts/gifts.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'razem', component: AboutusComponent },
  { path: 'slub', component: WeddingdetailsComponent },
  { path: 'nocleg', component: HotelComponent },
  { path: 'prezenty', component: GiftsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
